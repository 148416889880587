export const USER_PROFILE_IMAGE = '/image/svg_ic_profile.svg';
export const USER_PROFILE_FILL_IMAGE = '/image/svg_ic_profile_fill.svg';
export const USER_PROFILE_WHITE_IMAGE = '/image/svg_ic_profile_white.svg';
export const USER_LOGIN_IMAGE = '/image/svg_ic_login.svg';
export const COIN_DRAWER_FRAME_IMAGE = '/image/coin_drawer_frame.webp';
export const USER_LOGOUT_IMAGE = '/image/svg_ic_logout.svg';
export const DRAWER_FRAME_IMAGE = '/image/drawer_frame.svg';
export const USER_FAVORITE_IMAGE = '/image/svg_ic_star.svg';
export const USER_FAVORITE_FULL_IMAGE = '/image/svg_ic_star_full.svg';
export const USER_SETTING_IMAGE = '/image/svg_ic_setting.svg';

export const DARK_MODE_IMAGE = '/image/svg_ic_dark_mode.svg';
export const CHOOSE_LANGUAGE_IMAGE = '/image/svg_ic_choose_language.svg';
export const ABOUT_US_IMAGE = '/image/svg_ic_about_us.svg';
export const RULES_IMAGE = '/image/svg_ic_rules.svg';

export const REZI_IMAGE = '/image/png_ic_rezi.png';
export const SENFI_IMAGE = '/image/senfi.png';
export const REZI_URL = 'https://logo.samandehi.ir/Verify.aspx?id=275601&p=uiwkjyoedshwgvkaobpdrfth';
export const ENAMAD_IMAGE = 'https://Trustseal.eNamad.ir/logo.aspx?id=235030&Code=DKeATPe3kI2L8as0Bo56';
export const ENAMAD_URL = 'https://trustseal.enamad.ir/?id=235030&Code=DKeATPe3kI2L8as0Bo56';
export const SENFI_URL = 'https://tehran.irannsr.org/fa/trade_unit/2521946/swd_id/104366/اعضای-حقوقی-راهکار-های-همراه-گسترده-شهر-آینده.html';

export const CAFE_DOWNLOAD_IMAGE = '/image/cafeBazzar.svg';
export const PLAY_DOWNLOAD_IMAGE = '/image/googlePlay.svg';
export const MYKET_DOWNLOAD_IMAGE = '/image/myket.svg';
export const DIRECT_DOWNLOAD_IMAGE = '/image/direct_download_fa.svg';
export const DIRECT_DOWNLOAD_EN_IMAGE = '/image/direct_download_en.svg';

export const LINKEDIN_IMAGE = '/image/svg_ic_linkedin.svg';
export const INSTAGRAM_IMAGE = '/image/svg_ic_instagram.svg';
export const APARAT_IMAGE = '/image/svg_ic_aparat.svg';
export const DIRECT_IMAGE = '/image/android_icon.webp';

export const APARAT_URL = 'https://www.aparat.com/toolbox';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/jabeabzarapp/';
export const INSTAGRAM_URL = 'https://www.instagram.com/jabeabzar_app/?hl=en';

export const CAFE_TOOLBOX_URL = 'https://cafebazaar.ir/app/ir.shahbaz.SHZToolBox';
export const PLAY_TOOLBOX_URL = 'https://play.google.com/store/apps/details?id=ir.shahbaz.SHZToolBox_demo';
export const MYKET_TOOLBOX_URL = 'https://myket.ir/app/ir.shahbaz.SHZToolBox';

export const DIRECT_CAFE_TOOLBOX_URL = 'https://tlbxfiles.ir/download/Jabeabzar.apk';
export const DIRECT_PLAY_TOOLBOX_URL = 'https://tlbxfiles.ir/download/Toolbox.apk';
